<template>
<div>
  <section class="header-kurumsal">
    <div class="foto">
      <scroll-parallax direction="y"
                       :down="true"
                       :up="false"
                       speed="0.13">

      <img :src="about.img"
           data-aos="fade-right"
           data-aos-offset="0"
           data-aos-delay="0"
           data-aos-duration="700"
           data-aos-easing="ease-in"
           data-aos-mirror="true"
           data-aos-once="false"
           data-aos-anchor-placement="top-bottom">
      </scroll-parallax>

    </div>

    <div class="baslik">
      <h3>{{lang.gesas}}</h3>
      <h2>{{about.heading}}</h2>
    </div>

    <img class="bgcizgi" src="../../../public/assets/img/haber-detay-header-bg.png" />

  </section>

  <section class="icerik">

    <h3 data-aos="fade-up"
        data-aos-offset="0"
        data-aos-delay="0"
        data-aos-duration="700"
        data-aos-easing="ease-in"
        data-aos-mirror="true"
        data-aos-once="false"
        data-aos-anchor-placement="top-bottom"
    >{{lang.gesas}}</h3>

    <p data-aos="fade-up"
       data-aos-offset="0"
       data-aos-delay="0"
       data-aos-duration="700"
       data-aos-easing="ease-in"
       data-aos-mirror="true"
       data-aos-once="false"
       data-aos-anchor-placement="top-bottom"
    >
      {{about.desc}}
    </p>

<div class="fotolar">
  <img :src="about.img1"
       class="ic"
       data-aos="fade-up"
       data-aos-offset="0"
       data-aos-delay="0"
       data-aos-duration="700"
       data-aos-easing="ease-in"
       data-aos-mirror="true"
       data-aos-once="false"
       data-aos-anchor-placement="top-bottom"/>
  <img :src="about.img2"
       class="ic"
       data-aos="fade-up"
       data-aos-offset="0"
       data-aos-delay="0"
       data-aos-duration="700"
       data-aos-easing="ease-in"
       data-aos-mirror="true"
       data-aos-once="false"
       data-aos-anchor-placement="top-bottom" />
</div>
    <img src="../../../public/assets/img/sayfa-cizgi-yesil-2.png" class="bgcizgi"/>
  </section>
</div>
</template>
<script>
import iso from "../../axios";
export default {
  data(){
    return{
      about:[],
      lang:[],
    }
  },
  created() {

    iso.get('about')
        .then(response => {
          this.about = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('langfix')
        .then(response => {
          this.lang = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>